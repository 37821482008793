import { css } from "@emotion/core"

const containerStyle = ( theme ) => css`
    max-width: ${theme.grid.desktop + "vw"};
    margin: 0px auto;

    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-gap: 2em;
    grid-template-rows: 2;
    grid-row-gap: 6em;

    @media (max-width: ${ theme.breakpoint.mobile }) {
        grid-gap: initial;
        grid-row-gap: 4em;
    }
`;

export default containerStyle;