import { css } from "@emotion/core";

export const normalize = css`
    body, html {
        margin: 0;
        padding: 0;
        height: 100%;
    }

    * {
        box-sizing: border-box;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    small,
    blockquote {
        margin: 0;
        padding: 0;
    }

    ul,
    ol {
        margin: 0;
        padding: 0;

        list-style: none;
    }

    input {
        margin: 0;
        padding: 0;

        border: none;
    }

    a {
        text-decoration: none;
    }

    figure {
        margin: 0;
    }

    img {
        width: 100%;
    }
`;