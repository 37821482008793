import { css } from "@emotion/core";

const GlobalStyleConfiguration = ( theme ) => css`
    body {
        font-size: 16px;
        font-family: ${theme.fonts.body };
        background-color: ${ theme.colors.primary };
        color: ${ theme.colors.secondary };   

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;

        overflow-x: hidden;
    }

    h1 {
        font-size: 3.5em;
        font-family: ${ theme.fonts.heading };
        font-weight: 900;
    }

    h2 {
        font-size: 3.0em;
        font-family: ${ theme.fonts.heading };
        font-weight: 700;
    }

    p.intro {
        color: ${ theme.colors.tertiary };
        font-size: 1.375em;
        line-height: 1.4;
        font-family: ${ theme.fonts.heading };
    }

    p.secondary {
        color: ${ theme.colors.tertiary };
    }

    a {
        color: ${ theme.colors.secondary };
    }
`;

export default GlobalStyleConfiguration;