/** @jsx jsx */
import { jsx } from '@emotion/core';

import containerStyle from "./grid.styles";

const Container = ( {children} ) => (
    <div css={ theme => [containerStyle(theme)] }>
        { children }
    </div>
)

export default Container