/** @jsx jsx */
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import { jsx } from '@emotion/core';

import { list, nav, themeSwitch } from "./navigation.styles";


const Navigation = ({canNavigateBack, toggleTheme}) => {
    const menuItems = useStaticQuery(graphql`
        query {
            allWordpressMenusMenusItems(filter: {id: {eq: "04ae7632-5487-54e8-bf85-070ea22d5613"}}) {
                nodes {
                    items {
                        title
                        url
                        object_id
                    }
                }
            }
        }
    `).allWordpressMenusMenusItems.nodes[0].items

    const Menu = () => {
        const MenuItems = menuItems.map((item, index) => {
            return (
                <li key={ item.object_id }>
                    <a href={ item.url } target="_blank" rel="noopener noref">{ item.title }</a>
                </li>
            )
        })

        return (
            <ul css={ list }>
                { MenuItems }
                <li>
                    <div 
                        onClick = { toggleTheme }
                        css={ (theme) => themeSwitch(theme) }>
                    </div>
                </li>
            </ul>
        )
    }

    return (
        <nav css={ nav }>
            { canNavigateBack ? <a href="/">Go Back</a> : <p></p>}
            <Menu></Menu>
        </nav>
    )
};

Navigation.propTypes = {
    canNavigateBack: PropTypes.bool
}

export default Navigation;