import { css } from "@emotion/core"

export const nav = (theme) => css`
    display: flex;
    grid-column: 3 / span 9;
    grid-row: 1;
    justify-content: space-between;
    padding: 2.5em 0;

    p {
        display: none;
    }

    @media (max-width: ${ theme.breakpoint.mobile }) {
        grid-column: 1 / -1;
    }
`;

export const list = (theme) => css`
    list-style: none;
    display: inline;
    margin-left: auto;

    li {
        display: inline;
        padding: 0 1rem;

        &:first-of-type {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    @media (max-width: ${ theme.breakpoint.mobile }) {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-left: initial;
    }
`;

export const themeSwitch = (theme) => css`
    width: 0.75em;
    height: 0.75em;
    background-color: ${ theme.colors.secondary };
    border-radius: 50%;
    display: inline-block;
    position: relative;
    cursor: pointer;
    
    &::before {
        content: "";
        position: absolute;
        display: block;
        left: -0.25em;
        top: -0.25em;
        width: 1.25em;
        height: 1.25em;
        z-index: -1;
        background-color: rgba(128, 128, 128, 0.5);
        border-radius: 50%;
        transform: scale(0.5);
        transition: transform 250ms cubic-bezier(.215,.61,.355,1);
    }


    &:hover::before {
        transform: scale(1);
    }
`;
