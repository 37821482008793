const theme = {
    breakpoint: {
        mobile: `560px`
    },

    grid: {
        desktop: 90
    },

    fonts: {
        body: "-apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif",//"SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif",
        heading: "-apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif",//"SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif",
        mono: "-apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif"//"SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif"
    },
}

const lightTheme = {
    ...theme,

    colors: {
        primary: "#FFFFFF",
        secondary: "#0C0C0C",
        tertiary: "#86868B"
    }
}

const darkTheme = {
    ...theme,

    colors: {
        primary: "#0C0C0C",
        secondary: "#FFFFFF",
        tertiary: "#86868B"
    }
}

export {theme, lightTheme, darkTheme};