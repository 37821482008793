import { useState } from "react";

export const useDarkMode = () => {
    const [theme, setTheme] = useState('light');

    const toggleTheme = () => {
        console.log("Switchting theme", theme);
        if (theme === 'light') {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    }

    return [theme, toggleTheme]
}