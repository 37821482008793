import React, { useState } from "react";
import PropTypes from "prop-types";

import { Global } from "@emotion/core";
import { ThemeProvider } from 'emotion-theming';
import { lightTheme, darkTheme} from '../styles/theme/theme';

import GlobalStyleConfiguration from "../styles/global";

import { normalize } from "../styles/normalize";

import Container from "./shared/grid/grid";
import { useDarkMode } from "../hooks/themeSwitcherHook";
import Navigation from "./shared/navigation/navigation";

const Layout = ({ canNavigateBack, children }) => {
  const [theme, toggleTheme] = useDarkMode();
  
  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <Global styles={ theme => [normalize, GlobalStyleConfiguration(theme)] } /> 
      <Container>
        <Navigation canNavigateBack = { canNavigateBack } toggleTheme = { toggleTheme }></Navigation>
        { children }
      </Container>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
